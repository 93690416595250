import React, { useEffect, useState, useMemo } from "react"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import FormInput from "../olc-framework/FormInput"
import BlockCTA from "../configurable/BlockCTA"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import FormTextarea from "../olc-framework/FormTextarea"
import Col3 from "../grid/Col3"
import Col6 from "../grid/Col6"
import Col9 from "../grid/Col9"
import Col12 from "../grid/Col12"
import Col8 from "../grid/Col8"
import Col4 from "../grid/Col4"
import FormSelect from "~/components/olc-framework/FormSelect"

import ContactUsDetails from "~/vectors/contact-us-details.inline.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { useStaticQuery, graphql } from "gatsby"
import Icon from "../olc-framework/Icon"

import "./contact-us-new.scss"





const ContactUsPage = ({ location }) => {

  return (
    <div className="contact-us-page">
      <Hero
        imageUrl="/images/contact-banner.jpg"
        compact
      >
        <Heading level={1} underlined>
          Contact
        </Heading>
      </Hero>
      <Block>
        <div className="container">
          <div className="numbersRow row" >

            <Col4  className="numbersBlockItem" >
              <div>
                <img src={require('../../images/dom.png')} style={{  margin: 'auto' }} />
                <div className="numbersBlockItemTitle">
                  <strong >
                    Domestic customers
                  </strong><br />
                </div>
                <div>
                  <a
                    href="tel:02039954422"
                    style={{

                    }}
                  >
                    {/* <Icon alias="phone" /> */}
                    ⠀020 3995 4422
                  </a>
                </div>
                <div>
                  <a
                    href="mailto:support@get-uk.com"
                    style={{

                    }}
                  >
                    {/* <Icon alias="email" /> */}
                    ⠀support@get-uk.com
                  </a>
                </div>
              </div>
            </Col4>
            <Col4 className="numbersBlockItem">
              <div >
                <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                <div className="numbersBlockItemTitle">
                  <strong>
                    Solar Together
                  </strong>
                </div>
                <div>
                  <a href="tel:02081672206"><span style={{color:'#3c96c5',fontWeight:'bolder' }}>London 2022:</span> 020 8167 2206</a>
                </div>
                <div>
                  <a href="tel:02081672205"><span style={{color:'#3c96c5',fontWeight:'bolder' }}>Cambridge:</span> 020 8167 2205</a>
                </div>
                <div>
                  <a href="tel:01920452220"><span style={{color:'#3c96c5',fontWeight:'bolder' }}>Warwickshire:</span> 019 2045 2220</a>
                  </div>
                <div>
                  <a href="tel:02039898987"><span style={{color:'#3c96c5',fontWeight:'bolder' }}>London 2021:</span> 020 3989 8987</a>
                </div>
                <div>
                  <a
                    href="tel:02038669896"
                    style={{

                    }}
                  >
                    {/* <Icon alias="phone" /> */}
                    <span style={{color:'#3c96c5',fontWeight:'bolder' }}>Devon:</span> 020 3866 9896
                  </a>
                </div>
                <div>
                  <a
                    href="mailto:solar.together@get-uk.com"
                  >
                    {/* <Icon alias="email" /> */}
                    ⠀solar.together@get-uk.com
                  </a>
                </div>
              </div>
            </Col4>
            <Col4 className="numbersBlockItem">
              <div >
                <img src={require('../../images/com.png')} style={{ margin: 'auto' }} />
                <div className="numbersBlockItemTitle">
                  <strong >
                    Commercial enquiries
                  </strong>
                </div>
                <div>
                  <a
                    href="tel:01920452175"
                  >
                  01920 452 175
                  </a>
                </div>
                <div>
                  <a
                    href="mailto:commercial.enquiries@get-uk.com"

                  >
                    {/* <Icon alias="email" /> */}
                    commercial.enquiries@get-uk.com
                  </a>
                </div>
              </div>
            </Col4>

          </div>
          <div className="addressBlock row" style={{marginTop:'3em'}}>
            <Col12>
              <p className="companyAddress">
                <strong>
                  Our address:
                </strong>
                &nbsp; 8 Peerglow Center,
                Marsh Lane
                Ware,
                Hertfordshire,
                SG12 9QL
                Registration: 09750103
              </p>
            </Col12>
          </div>
        </div>
      </Block>
    </div>
  )
}

export default ContactUsPage
